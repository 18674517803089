import _isArray from 'lodash/isArray'
import { DateTime, Interval } from 'luxon'
import consola from 'consola'
import conversionTimestamp from '../../../plugins/filters/conversionTimestamp/conversionTimestamp.filter'
import getNDigitNumber from '../../../plugins/filters/getNDigitNumber/getNDigitNumber.filter'
import formatTimeUtil from '../../npvr/shared/formatTime/formatTime.utils'
import npvrConstants from '../../npvr/shared/npvr.config'

/**
 * ProgramField array contains all fields that can be converted to a string with ProgramFormat class.
 */
export const ProgramField = {
  CHANNEL_NAME: 'CHANNEL_NAME',
  TITLE: 'TITLE',
  SUBSCRIBE_STATUS: 'SUBSCRIBE_STATUS',
  CSA_LABEL: 'CSA_LABEL',
  RECORDING_STATUS: 'RECORDING_STATUS',
  REMAINING_TIME: 'REMAINING_TIME',
  DURATION: 'DURATION',
  START_TIME: 'START_TIME',
  TIME_SLOT: 'TIME_SLOT',
  GENRE: 'GENRE',
  EPISODE_TITLE: 'EPISODE_TITLE',
  SXXEYY: 'SXXEYY'
}

/**
 * ProgramFieldPresets provides a set of predefined ProgramField arrays, useful in some app pages.
 */
export const ProgramFieldPresets = {
  HomeCover: [
    ProgramField.CHANNEL_NAME,
    ProgramField.SUBSCRIBE_STATUS,
    ProgramField.TITLE,
    ProgramField.CSA_LABEL,
    ProgramField.RECORDING_STATUS
  ],
  HomeInfos: [
    ProgramField.CHANNEL_NAME,
    ProgramField.SUBSCRIBE_STATUS,
    ProgramField.TITLE,
    ProgramField.CSA_LABEL,
    ProgramField.GENRE,
    ProgramField.SXXEYY,
    ProgramField.EPISODE_TITLE,
    ProgramField.DURATION,
    ProgramField.REMAINING_TIME,
    ProgramField.RECORDING_STATUS
  ],
  ProgramInfos: [
    ProgramField.TITLE,
    ProgramField.CSA_LABEL,
    ProgramField.TIME_SLOT,
    ProgramField.DURATION,
    ProgramField.GENRE,
    ProgramField.SXXEYY,
    ProgramField.EPISODE_TITLE,
    ProgramField.CHANNEL_NAME,
    ProgramField.SUBSCRIBE_STATUS,
    ProgramField.START_TIME,
    ProgramField.RECORDING_STATUS
  ],
  EveningInfos: [
    ProgramField.CHANNEL_NAME,
    ProgramField.SUBSCRIBE_STATUS,
    ProgramField.TITLE,
    ProgramField.CSA_LABEL,
    ProgramField.GENRE,
    ProgramField.SXXEYY,
    ProgramField.EPISODE_TITLE,
    ProgramField.START_TIME,
    ProgramField.DURATION,
    ProgramField.TIME_SLOT,
    ProgramField.RECORDING_STATUS
  ],
  FipWatchButton: [
    ProgramField.CHANNEL_NAME,
    ProgramField.SUBSCRIBE_STATUS,
    ProgramField.TITLE,
    ProgramField.CSA_LABEL,
    ProgramField.GENRE,
    ProgramField.SXXEYY,
    ProgramField.EPISODE_TITLE,
    ProgramField.START_TIME,
    ProgramField.DURATION,
    ProgramField.TIME_SLOT,
    ProgramField.RECORDING_STATUS
  ],
  FipRecordButton: [
    ProgramField.CHANNEL_NAME,
    ProgramField.TITLE,
    ProgramField.CSA_LABEL,
    ProgramField.GENRE,
    ProgramField.SXXEYY,
    ProgramField.EPISODE_TITLE,
    ProgramField.START_TIME,
    ProgramField.DURATION
  ],
  AvailableCover: [
    ProgramField.CHANNEL_NAME,
    ProgramField.TITLE,
    ProgramField.CSA_LABEL,
    ProgramField.SXXEYY,
    ProgramField.EPISODE_TITLE,
    ProgramField.RECORDING_STATUS
  ],
  AvailableInfos: [
    ProgramField.CHANNEL_NAME,
    ProgramField.TITLE,
    ProgramField.CSA_LABEL,
    ProgramField.GENRE,
    ProgramField.SXXEYY,
    ProgramField.EPISODE_TITLE,
    ProgramField.START_TIME,
    ProgramField.DURATION,
    ProgramField.TIME_SLOT
  ],
  ProgrammedInfos: [
    ProgramField.CHANNEL_NAME,
    ProgramField.TITLE,
    ProgramField.CSA_LABEL,
    ProgramField.GENRE,
    ProgramField.SXXEYY,
    ProgramField.EPISODE_TITLE,
    ProgramField.START_TIME,
    ProgramField.DURATION,
    ProgramField.TIME_SLOT,
    ProgramField.RECORDING_STATUS
  ],
  DeleteAndEditButton: [
    ProgramField.TITLE,
    ProgramField.SXXEYY,
    ProgramField.EPISODE_TITLE
  ],
  AllProgramsInfos: [
    ProgramField.START_TIME,
    ProgramField.SUBSCRIBE_STATUS,
    ProgramField.TITLE,
    ProgramField.CSA_LABEL,
    ProgramField.GENRE,
    ProgramField.SXXEYY,
    ProgramField.EPISODE_TITLE,
    ProgramField.DURATION,
    ProgramField.RECORDING_STATUS
  ]
}

/**
 * ProgramFormat class provides getters to generate individual strings representing program fields.
 * It also provides a format() method to generate a multi-fields string, taking care of empty fields and coma separation.
 */
export class ProgramFormat {
  /**
   * ProgramFormat Constructor
   * @constructor
   * @param  {Object} $root a Vue Component that has access to store, i18n and filters.
   * @param  {Object} program A live program
   * @param  {Object} channel=null A live channel (which can also be provided through a member of program like in Home Page)
   */
  constructor ($root, program, channel = null) {
    this.$root = $root

    if (!program) {
      throw new Error('ProgramFormat constructor called whith null or undefined program')
    }

    this.program = program
    this.channel = program.channel ? program.channel : channel
  }

  /**
   * Get the channel name (i.e. "TF1")
   * @returns {(string|null)} The channel name or null
   */
  get channelName () {
    return this.channel?.name ? this.channel.name : null
  }

  /**
   * Get the program title (i.e. "Friends")
   * @returns {(string|null)} The program title or null
   */
  get title () {
    let title = this.program.title

    if (this.program.programType === 'EPISODE') {
      title = this.program.season?.serie?.title
    }

    return title || null
  }

  /**
   * Get the channel subscription status (i.e. "Chaîne non souscrite")
   * @return {(string|null)} The channel subscription status or null
   */
  get subscribeStatus () {
    return this.$root.$store.getters['npvr/notSubscribed'](this.channel)
      ? this.$root.$t(`accessibilite.accessibilite_chaine_non_souscrite`)
      : null
  }

  /**
   * Get the CSA label (i.e. "Déconseillé aux moins de 18 ans")
   * @returns {(string|null)} The CSA label or null
   */
  get csaLabel () {
    if (!this.program.csa || this.program.csa < 1 || this.program.csa > 5) {
      return null
    }

    return this.program.csa === 1 ? null : this.$root.$t(`general.csa.${this.program.csa}`)
  }

  /**
   * Get the program recording status (i.e. "Enregistrement programmé")
   * @returns {(string|null)} The program recording status or null
   */
  get recordingStatus () {
    const recording = this.$root.$store.getters['npvr/getProgramRecording'](this.program, this.channel)

    if (!recording) {
      return null
    }

    if (recording.status === 'FUTURE_RECORDING') {
      return this.$root.$t(`fip.recording.recording_programmed`)
    } else if (recording.status === 'IN_RECORDING') {
      return this.$root.$t(`fip.recording.recording_in_progress`)
    } else {
      return null
    }
  }

  /**
   * Get the program remaining time (i.e. "Reste 30 minutes")
   * @returns {(string|null)} The program remaining time or null
   */
  get remainingTime () {
    let endDiffusionDate = this.program.endDiffusionDate

    if (!endDiffusionDate) {
      endDiffusionDate = (this.program.diffusionDate + this.program.duration) * 1000
    }

    const interval = Interval.fromDateTimes(DateTime.utc(), DateTime.fromMillis(endDiffusionDate)).count('minutes')
    if (!interval || isNaN(interval)) {
      return null
    }
    return interval ? this.$root.$tc('general.items.remainingTimeAlternate', interval) : null
  }

  /**
   * Get the program duration (i.e. "30min")
   * @returns {(string|null)} The program duration or null
   */
  get duration () {
    if (!this.program.duration) {
      return null
    }
    const durationMinutes = formatTimeUtil.formatDurationToHoursAndMins(this.program.duration * 1000)

    if (!durationMinutes) {
      return null
    }
    if (durationMinutes === '00min') {
      return 'Moins d\'une minute'
    }

    return durationMinutes
  }

  /**
   * Get the program start time (i.e. "20h50")
   * @returns {(string|null)} The program start time or null
   */
  get startTime () {
    if (!this.program.diffusionDate) {
      return null
    }

    return this.$root.$t('npvr.broadcastTime', { date: `${DateTime.fromSeconds(this.program.diffusionDate).setLocale('fr').toFormat('dd MMMM')}` })
  }

  /**
   * Get the program start time (i.e. "20h50")
   * @returns {(string|null)} The program start time or null
   */
  get timeSlot () {
    if (!this.program.diffusionDate || !this.program.duration) {
      return null
    }

    const start = conversionTimestamp(this.program.diffusionDate)
    const end = conversionTimestamp(this.program.diffusionDate + this.program.duration)
    return `${start} - ${end}`
  }

  /**
   * Get the program genre (i.e. "Film")
   * @returns {(string|null)} The program genre or null
   */
  get genre () {
    if (!this.program.genre) {
      return null
    }

    return this.program.genre
  }

  /**
   * Get the episode title (i.e. "Celui qui avait l'\Unagi")
   * @returns {(string|null)} The episode title or null
   */
  get episodeTitle () {
    if (this.program.programType === 'EPISODE') {
      return this.program.title
    }

    return null
  }

  /**
   * Get the episode SXXEYY (i.e. "Saison 6 épisode 17")
   * @returns {(string|null)} The episode SXXEYY or null
   */
  get sxxeyy () {
    if (this.program.programType === 'EPISODE') {
      return this.getSxxEyy(this.program.season.number, this.program.episodeNumber)
    }

    return null
  }

  getSxxEyy (sNumber, eNumber) {
    if (sNumber && eNumber && !isNaN(sNumber) && !isNaN(eNumber)) {
      return `S${getNDigitNumber(sNumber, 2)}E${getNDigitNumber(eNumber, 2)}`
    }
    return null
  }

  /**
   * Internal method that creates a mapping of ProgramField -> getter, useful in format() method.
   */
  _setGettersMap () {
    if (!this.gettersMap) {
      this.gettersMap = new Map()
      this.gettersMap.set(ProgramField.CHANNEL_NAME, () => this.channelName)
      this.gettersMap.set(ProgramField.TITLE, () => this.title)
      this.gettersMap.set(ProgramField.SUBSCRIBE_STATUS, () => this.subscribeStatus)
      this.gettersMap.set(ProgramField.CSA_LABEL, () => this.csaLabel)
      this.gettersMap.set(ProgramField.RECORDING_STATUS, () => this.recordingStatus)
      this.gettersMap.set(ProgramField.REMAINING_TIME, () => this.remainingTime)
      this.gettersMap.set(ProgramField.DURATION, () => this.duration)
      this.gettersMap.set(ProgramField.START_TIME, () => this.startTime)
      this.gettersMap.set(ProgramField.TIME_SLOT, () => this.timeSlot)
      this.gettersMap.set(ProgramField.GENRE, () => this.genre)
      this.gettersMap.set(ProgramField.EPISODE_TITLE, () => this.episodeTitle)
      this.gettersMap.set(ProgramField.SXXEYY, () => this.sxxeyy)
    }
  }

  /**
   * Generates a string with concatenated field strings of a program (i.e.: "21h00, Mars Attack, Film")
   * If any of specified field doesn't exist in the program, it is simply omitted from the output string.
   * @param  {Array} fields An array of ProgramField strings that describes which fields must be included in output format string.
   * @param  {String} prefix=null An optional prefix that will be prepended to the output string.
   * @returns {(string|null)} The output format string or null.
   */
  format (fields, prefix = null) {
    this._setGettersMap()

    if (!_isArray(fields) || fields.length < 1) {
      return null
    }

    const outputStringFields = []

    // Loop over provided ProgramField array and call related getter.
    // Put the getter output in the outputStringFields array.
    fields.forEach((field) => {
      const getter = this.gettersMap.get(field)
      try {
        const outputStringField = getter()

        if (outputStringField) {
          outputStringFields.push(outputStringField)
        }
      } catch (e) {
        consola.error(`Invalid Program field ${field}`)
        return null
      }
    })
    // Join all found fields.
    let formatString = outputStringFields.join(', ')

    // Optionally adds prefix.
    if (prefix) {
      formatString = `${prefix} ${formatString}`
    }

    // Finished!
    return formatString
  }
}

export class ProgramFormatNPVR {
  /**
   * ProgramFormat Constructor
   * @constructor
   * @param  {Object} $root a Vue Component that has access to store, i18n and filters.
   * @param  {Object} program A live program
   * @param  {Object} channel=null A live channel (which can also be provided through a member of program like in Home Page)
   */
  constructor ($root, item) {
    this.$root = $root
    if (!item) {
      throw new Error('ProgramFormat constructor called whith null or undefined program')
    }
    this.program = item.content
    this.channel = item.channel
    this.item = item
  }

  /**
   * Get the channel name (i.e. "TF1")
   * @returns {(string|null)} The channel name or null
   */
  get channelName () {
    return this.channel?.name ? this.channel.name : null
  }

  /**
   * Get the program title (i.e. "Friends")
   * @returns {(string|null)} The program title or null
   */
  get title () {
    const title = this.program.seasonNumber ? this.program.serieTitle : this.program.title

    return title || null
  }

  /**
   * Get the CSA label (i.e. "Déconseillé aux moins de 18 ans")
   * @returns {(string|null)} The CSA label or null
   */
  get csaLabel () {
    const csaCodes = ['TP', '10', '12', '16', '18']
    if (this.program.csa) {
      if (this.program.csa < 1 || this.program.csa > 5) {
        return null
      }

      return this.program.csa === 1 ? null : this.$root.$t(`general.csa.${this.program.csa}`)
    } else if (this.program.csaCode && csaCodes.includes(this.program.csaCode)) {
      const csa = npvrConstants.CSA_CODES[this.program.csaCode]

      return csa === 1 ? null : this.$root.$t(`general.csa.${csa}`)
    } else {
      return null
    }
  }

  /**
   * Get the program recording status (i.e. "Enregistrement programmé")
   * @returns {(string|null)} The program recording status or null
   */
  get recordingStatus () {
    if (this.item.status === 'FUTURE_RECORDING') {
      return this.$root.$t(`fip.recording.recording_programmed`)
    } else if (this.item.status === 'IN_RECORDING') {
      return this.$root.$t(`fip.recording.recording_in_progress`)
    } else {
      return null
    }
  }

  /**
   * Get the program duration (i.e. "30 minutes")
   * @returns {(string|null)} The program duration or null
   */
  get duration () {
    if (!this.program.duration) {
      return null
    }
    const durationMinutes = formatTimeUtil.formatDurationToHoursAndMins(this.program.duration * 1000)

    if (!durationMinutes) {
      return null
    }
    if (durationMinutes === '00min') {
      return 'Moins d\'une minute'
    }

    return durationMinutes
  }

  /**
   * Get the program start time (i.e. "20h50")
   * @returns {(string|null)} The program start time or null
   */
  get startTime () {
    if (!this.program.startDate) {
      return null
    }
    return this.$root.$t('npvr.broadcastTime', { date: `${DateTime.fromISO(this.program.startDate).setLocale('fr').toFormat('dd MMMM')}` })
  }

  /**
   * Get the program start time (i.e. "20h50")
   * @returns {(string|null)} The program start time or null
   */
  get timeSlot () {
    if (!this.program.startDate || !this.program.duration) {
      return null
    }

    const start = DateTime.fromISO(this.program.startDate, { zone: 'utc' }).setZone('Europe/Paris')
    const end = start.plus({ seconds: this.program.duration })

    return `${start.toFormat(`HH'h'mm`)} - ${end.toFormat(`HH'h'mm`)}`
  }

  /**
   * Get the program genre (i.e. "Film")
   * @returns {(string|null)} The program genre or null
   */
  get genre () {
    if (!this.program.genres?.length > 0) {
      return null
    }

    return this.program.genres[0].label
  }

  /**
   * Get the episode title (i.e. "Celui qui avait l'\Unagi")
   * @returns {(string|null)} The episode title or null
   */
  get episodeTitle () {
    if (this.program.seasonNumber) {
      return this.program.title
    }

    return null
  }

  /**
   * Get the episode SXXEYY (i.e. "Saison 6 épisode 17")
   * @returns {(string|null)} The episode SXXEYY or null
   */
  get sxxeyy () {
    const sxxeyy = this.getSxxEyy(this.program.seasonNumber, this.program.episodeNumber)
    if (this.program.serieTitle && sxxeyy) {
      return sxxeyy
    }
    return null
  }

  getSxxEyy (sNumber, eNumber) {
    if (sNumber && eNumber && !isNaN(sNumber) && !isNaN(eNumber)) {
      return `S${getNDigitNumber(sNumber, 2)}E${getNDigitNumber(eNumber, 2)}`
    }
    return null
  }

  /**
   * Internal method that creates a mapping of ProgramField -> getter, useful in format() method.
   */
  _setGettersMap () {
    if (!this.gettersMap) {
      this.gettersMap = new Map()
      this.gettersMap.set(ProgramField.CHANNEL_NAME, () => this.channelName)
      this.gettersMap.set(ProgramField.TITLE, () => this.title)
      this.gettersMap.set(ProgramField.CSA_LABEL, () => this.csaLabel)
      this.gettersMap.set(ProgramField.RECORDING_STATUS, () => this.recordingStatus)
      this.gettersMap.set(ProgramField.DURATION, () => this.duration)
      this.gettersMap.set(ProgramField.START_TIME, () => this.startTime)
      this.gettersMap.set(ProgramField.TIME_SLOT, () => this.timeSlot)
      this.gettersMap.set(ProgramField.GENRE, () => this.genre)
      this.gettersMap.set(ProgramField.EPISODE_TITLE, () => this.episodeTitle)
      this.gettersMap.set(ProgramField.SXXEYY, () => this.sxxeyy)
    }
  }

  /**
   * Generates a string with concatenated field strings of a program (i.e.: "21h00, Mars Attack, Film")
   * If any of specified field doesn't exist in the program, it is simply omitted from the output string.
   * @param  {Array} fields An array of ProgramField strings that describes which fields must be included in output format string.
   * @param  {String} prefix=null An optional prefix that will be prepended to the output string.
   * @returns {(string|null)} The output format string or null.
   */
  format (fields, prefix = null) {
    this._setGettersMap()

    if (!_isArray(fields) || fields.length < 1) {
      return null
    }

    const outputStringFields = []

    // Loop over provided ProgramField array and call related getter.
    // Put the getter output in the outputStringFields array.
    fields.forEach((field) => {
      const getter = this.gettersMap.get(field)
      try {
        const outputStringField = getter()

        if (outputStringField) {
          outputStringFields.push(outputStringField)
        }
      } catch (e) {
        consola.error(`Invalid Program field ${field}`)
        return null
      }
    })

    // Join all found fields.
    let formatString = outputStringFields.join(', ')

    // Optionally adds prefix.
    if (prefix) {
      formatString = `${prefix} ${formatString}`
    }

    // Finished!
    return formatString
  }
}
