import { Duration, DateTime } from 'luxon'
import getTwoDigitNumber from '../../../../plugins/filters/getTwoDigitNumber/getTwoDigitNumber.filter'
export default {
  formatDurationToHoursAndMins (durationInMillis = 0) {
    const durationObjInHoursAndMins = Duration.fromMillis(durationInMillis).shiftTo('hours', 'minutes').toObject()
    if (durationInMillis < 7200000) {
      return Duration.fromObject(durationObjInHoursAndMins).toFormat(`mm'min'`)
    } else {
      return Duration.fromObject(durationObjInHoursAndMins).toFormat(`h'h'mm`)
    }
  },

  getFormattedTimeInHoursAndMins (dateInMillis = 0) {
    const dateObj = DateTime.fromMillis(dateInMillis).toObject()
    return DateTime.fromObject({ hour: dateObj.hour, minute: dateObj.minute }).toFormat(`HH'h'mm`)
  },

  formatTimeToObject (timestamp = 0) {
    const endTimeObj = DateTime.fromMillis(timestamp).toObject()
    const hours = endTimeObj.hour
    const minutes = endTimeObj.minute

    return {
      hours: getTwoDigitNumber(hours),
      minutes: getTwoDigitNumber(minutes)
    }
  },

  formatMinutesToMillis (minutes) {
    return minutes * 60 * 1000
  }
}
