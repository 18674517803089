<template>
  <div class="header">
    <h1
      v-if="sticked"
      aria-hidden="true"
      class="title mb-0">
      {{ $t('general.title') }}
    </h1>
    <ul class="menu">
      <li
        v-for="(route, idx) in routes"
        :key="idx">
        <router-link
          v-tealium:click="route.stats"
          :aria-label="route.aria"
          :to="route.to === 'AvailableRecordings' ? { name: route.to } : { name: route.to, query: { filtres: $route.query.filtres } }"
          :data-wptv="route.dataWptv"
          :class="{ active: getCurrentPage === route.to }"
          aria-current-value="page">
          <div>
            <span
              class="mb-0 menu-item">
              <wptv-icon
                v-if="route.icon"
                :name="route.icon"
                :specific-alt="''"
                class="icon icon-default"
                height="1em"
                width="1em" />
              <wptv-icon
                v-if="route.icon"
                :name="`${route.icon}-grey`"
                :specific-alt="''"
                class="icon icon-hover"
                height="1em"
                width="1em" />
              <span class="text"> {{ route.name }} </span>
            </span>
          </div>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { WptvIcon } from '@wptv/components/ui/icon'

export default {
  name: 'MainMenu',
  components: { WptvIcon },
  props: {
    routes: {
      type: Array,
      required: true
    },
    sticked: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      routeName: 'route/getRouteName'
    }),
    getCurrentPage () {
      return this.$route.name
    }
  }
}
</script>

<style lang="scss" scoped>
  .header {
    flex: 1;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;

    >h1 {
      margin-right: 45px;
      @include mediaQueries('display', (xsmall: none, medium: inline-block));
    }

    .menu {
      font-weight: 700;
      line-height: 1.43;
      display: inline-flex;
      flex: 1;
      @include mediaQueries('justify-content', (xsmall: space-between, medium: flex-start));

      li {
        @include mediaQueries('margin-right', (medium: 30px));
        @media (max-width: map-get($breakpoints, 'medium')) {
          flex-shrink: 0;
          flex-grow: 1;
        };

        &:hover {
          color: $primary
        }

        &:nth-child(4) { // mes enregistrements is the 4th item and this doesn't fuck up npvr menu
          margin: 0 0 0 auto;
          display: flex;
          align-items: center;
          .menu-item {
            @include mediaQueries('padding', (xsmall:  0px 0 4px, medium: 20px 0 14px));
          }
          .text {
            @include mediaQueries('display', (xsmall: none, large: inline-block));
          }
        }

        a {
          cursor: pointer;
          display: inline-block;
          width: 100%;
          text-align: center;
          margin-left: 0;

          .menu-item {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            @include mediaQueries('padding', (xsmall:  11px 8px, medium: 25px 0 14px));
            @include mediaQueries('font-size', (xsmall: 14px, medium: 16px, large: 18px));
            @include mediaQueries('margin-right', (xsmall:  0, medium: -5px, large: 0));
            .icon {
              margin-right: 10px;
              @include mediaQueries('font-size', (xsmall: 21px, medium: 30px));
            }

            .icon-hover {
              display: none;
            }
          }

          &.active {
            border-bottom: 3px solid $primary;
            @include mediaQueries('border-bottom', (medium: 6px solid $primary));
          }

          &:hover {
            .icon-default {
              display: none;
            }

            .icon-hover {
              display: block;
            }
          }
        }
      }
    }
  }
</style>
